<div class ="tenders">
    <section class="ac-container">
        <div>
            <input id="ac-1" name="accordion-1" type="radio" checked="">
            <label for="ac-1">Current Tenders</label>
            <article class="ac-small">
                <table>
                    <caption>Statement Summary</caption>
                    <thead>
                      <tr>
                        <th class ="table-heading" scope="col">Account</th> 
                        <th class ="table-heading" scope="col">Due Date</th>
                 
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label="Account">	
                            Mini Tender MBD Forms - Download</td>
                        <td data-label="Due Date">04/01/2016</td>
             
                      </tr>
                      <tr>
                        <td scope="row" data-label="Account">Visa - 6076</td>
                        <td data-label="Due Date">03/01/2016</td>
       
                      </tr>
                      <tr>
                        <td scope="row" data-label="Account">Corporate AMEX</td>
                        <td data-label="Due Date">03/01/2016</td>
            
                      </tr>
                      <tr>
                        <td scope="row" data-label="Acount">Visa - 3412</td>
                        <td data-label="Due Date">02/01/2016</td>
      
                      </tr>
                    </tbody>
                  </table>
            </article>
        </div>
        <div>
            <input id="ac-2" name="accordion-1" type="radio">
            <label for="ac-2">Closed Tenders</label>
            <article class="ac-medium">
                <p>Like you, I used to think the world was this great place where everybody lived by the same standards I did, then some kid with a nail showed me I was living in his world, a world where chaos rules not order, a world where righteousness is not rewarded. That's Cesar's world, and if you're not willing to play by his rules, then you're gonna have to pay the price. </p>
            </article>
        </div>
        <div>
            <input id="ac-3" name="accordion-1" type="radio">
            <label for="ac-3">Tender Awards</label>
            <article class="ac-large">
                <p>You think water moves fast? You should see ice. It moves like it has a mind. Like it knows it killed the world once and got a taste for murder. After the avalanche, it took us a week to climb out. Now, I don't know exactly when we turned on each other, but I know that seven of us survived the slide... and only five made it out. Now we took an oath, that I'm breaking now. We said we'd say it was the snow that killed the other two, but it wasn't. Nature is lethal but it doesn't hold a candle to man. </p>
            </article>
        </div>
        <div>
            <input id="ac-4" name="accordion-1" type="radio">
            <label for="ac-4">Intention to Award</label>
            <article class="ac-large">
                <p>You see? It's curious. Ted did figure it out - time travel. And when we get back, we gonna tell everyone. How it's possible, how it's done, what the dangers are. But then why fifty years in the future when the spacecraft encounters a black hole does the computer call it an 'unknown entry event'? Why don't they know? If they don't know, that means we never told anyone. And if we never told anyone it means we never made it back. Hence we die down here. Just as a matter of deductive logic. </p>
            </article>
        </div>
        <div>
            <input id="ac-5" name="accordion-1" type="radio">
            <label for="ac-5">Opening Registers</label>
            <article class="ac-large">
                <p>You see? It's curious. Ted did figure it out - time travel. And when we get back, we gonna tell everyone. How it's possible, how it's done, what the dangers are. But then why fifty years in the future when the spacecraft encounters a black hole does the computer call it an 'unknown entry event'? Why don't they know? If they don't know, that means we never told anyone. And if we never told anyone it means we never made it back. Hence we die down here. Just as a matter of deductive logic. </p>
            </article>
        </div>

    </section>
</div>