<div class ="governancePage container">
    <h1>Local Governance</h1>
    <h5>Local governance undertakes the administrative, legislative, executive, implementation, co-ordination, and oversight roles in the local municipal body. Our aim is to develop the community into a cohesive and resilient whole by addressing challenges posed by poverty, social and economic division and inequality.</h5>

    <h6>
        Offices of local government comprise of:</h6>
<ul class = "row">
    <li class="col-md-12"> Local Governance </li>
        <li class="col-md-12">Council</li>
        <li class="col-md-12">Executive Committee (Exco)</li>
        <li class="col-md-12">Portfolio Committees</li>
        <li class="col-md-12">Ward Councilorsr</li>
        <li class="col-md-12">PR Councillors</li>
        <li class="col-md-12">Office of the Mayor</li>
        <li class="col-md-12">Office of the Speake</li>
</ul>
     
</div>
