import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'RNM';
  content = [];


  constructor ( private http:HttpClient ){

  }
  ngOnInit(): void{
      this.http.get('https://llcalendars.co.za/wp/wp-json/wp/v2/posts').toPromise().then(data => {
          console.log('data',data)
  
          for (let key in data)
          if (data.hasOwnProperty(key))
          this.content.push(data[key])
  
         
      })
}
}
