<link rel="stylesheet" href="//cdn.jsdelivr.net/fontawesome/4.2.0/css/font-awesome.min.css" />
<link href='https://fonts.googleapis.com/css?family=Slabo+27px|Open+Sans' rel='stylesheet' type='text/css'>
<!-- TOP NAV -->

<nav  class="navbar navbar-expand-lg navbar-light bg-dark">

    <div class="container-fluid ">
      <a routerLink=""  routerLinkActive="active">
        <span class= "brandImage"> 
        <img src="assets/images/ray-nkonyeni-logo.png">
      </span>
        <!-- <a routerLink="/main-page" routerLinkActive="active">tester</a> -->
       
      </a> 
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarScroll">
        <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#"></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link " href="#" id="navbarScrollingDropdown" role="button"  aria-expanded="false">
           
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
              <li><a class="dropdown-item" href="#"></a></li>
              <li><a class="dropdown-item" href="#"></a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#"></a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true"></a>
          </li>
        </ul>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
          
        </form>
        <li class="nav-item">
            <a class="nav-link" href="#">Contact Us</a>
          </li>
      </div>
    </div>
  </nav>
 
<nav>
    
  <!-- <ul id="header">
    <a href="#" id="brand">
      <li>
        Pure CSS Dropdown
     <img src="../assets/ray-nkonyeni-logo.png"> 
      </li>
    </a>
    <label id="trigger2" for="x7"><i class="fa fa-bars"></i></label>
  </ul> -->

  <ul class="mainUL " id="main" >
    <a href="#">
      <!-- <li class="x1">
        Link 1
      </li> -->
   
    </a>

    <a href="#">
      <li class="x1">
        <ul class = "navbar-cabinet" >
        
            <li ><a routerLink="/Departments" routerLinkActive="active">Departments</a> </li>
            <li>Office of the Municipal Officer</li>
            <li>Treasury</li>
            <li>Strategic Planning and Governance</li>
            <li>Development Planning Services</li>
            <li>Community Services</li>
            <li>Technical Services</li>
            <li>Corporate Services</li>
            <li>Public Services</li>
          </ul>
      </li>
    </a>

        <a href="#">
            <li class="x1">
              <ul class = "navbar-cabinet">
                <li ><a routerLink="/Governance" routerLinkActive="active">Governance</a> </li>
                  <li>Governance</li>
                  <li>Council</li>
                  <li>Executive Committee (Exco)</li>
                  <li>Ward Councilors</li>
                  <li>PR Councillors</li>
                  <li>Office of the Mayor</li>
                  <li>Office of the Speaker</li>
                </ul>
            </li>
          </a>

  
 
        <a href="#">
            <li class="x1">
              <ul class = "navbar-cabinet">
                <li ><a routerLink="/Tenders" routerLinkActive="active">Tenders</a> </li>
                <li>Current Tenders</li>
                  <li>Closed</li>
                  <li>Tender Awards</li>
                  <li>Intention to Award</li>
                  <li>Opening Registers</li>
                  <li class = "Ghost-Item">RNM</li>
                  <li class = "Ghost-Item">RNM</li>
                  <li class = "Ghost-Item">RNM</li>
                  <li class = "Ghost-Item">RNMasdsdf
                      sdfsdf sdfsdfsdf </li>
                  
                </ul>
            </li>
          </a>


        <a href="#">
            <li class="x1">
              <ul class = "navbar-cabinet">
                <li ><a routerLink="/More" routerLinkActive="active">More</a> </li>
                  <li>Vacancies</li>
                  <li>Documents</li>
                  <li>Municipal Notices</li>
                  <li>Top Management Profiles</li>
                  
       
                </ul>

                


            </li>
          </a>

   
 
    
    <!-- <a href="#" id="trigger">
      <li class="x1">
        Dropdown&nbsp;
        <span id="caret"></span>
      </li>
    </a> -->
    <ul class = "mainUL" id="dropdown">
      <a href="#">
        <li>
          Link 1
        </li>
      </a>
      <a href="#">
        <li>
          Link 2
        </li>
      </a>
      <a href="#">
        <li>
          Link 3
        </li>
      </a>
      <a href="#">
        <li>
          Link 4
        </li>
      </a>
    </ul>
  </ul>
</nav>


<main>

    
