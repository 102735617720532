<body>
	
    <div class="container">
      
      <div class="team">
        <div class="member">
          <img src="./assets/images/departments/Head of Department - Corporate Services photo of Ms Nelisiwe Thabatha .jpg" alt="member_image">
          <h3>Head of Department</h3>
          <span>doe@gmail.com</span>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.amet consecteturamet consecteturamet Laboriosam voluptatum fuga iure. Est, dicta voluptatum.</p>
          <div class="btn">
            <a href="#">See Department</a>
          </div>
        </div>
        <div class="member">
            <img src="./assets/images/departments/Head of Department - Corporate Services photo of Ms Nelisiwe Thabatha .jpg" alt="member_image">
            <h3>Head of Department</h3>
            <span>doe@gmail.com</span>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.amet consecteturamet consecteturamet Laboriosam voluptatum fuga iure. Est, dicta voluptatum.</p>
            <div class="btn">
              <a href="#">See Department</a>
            </div>
          </div>
          <div class="member">
            <img src="./assets/images/departments/Head of Department - Corporate Services photo of Ms Nelisiwe Thabatha .jpg" alt="member_image">
            <h3>Head of Department</h3>
            <span>doe@gmail.com</span>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.amet consecteturamet consecteturamet Laboriosam voluptatum fuga iure. Est, dicta voluptatum.</p>
            <div class="btn">
              <a href="#">See Department</a>
            </div>
          </div>

        <div class="member">
            <img src="./assets/images/departments/Head of Department - Corporate Services photo of Ms Nelisiwe Thabatha .jpg" alt="member_image">
            <h3>Head of Department</h3>
            <span>doe@gmail.com</span>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.amet consecteturamet consecteturamet Laboriosam voluptatum fuga iure. Est, dicta voluptatum.</p>
            <div class="btn">
              <a href="#">See Department</a>
            </div>
          </div>

          <div class="member">
            <img src="./assets/images/departments/Head of Department - Corporate Services photo of Ms Nelisiwe Thabatha .jpg" alt="member_image">
            <h3>Head of Department</h3>
            <span>doe@gmail.com</span>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.amet consecteturamet consecteturamet Laboriosam voluptatum fuga iure. Est, dicta voluptatum.</p>
            <div class="btn">
              <a href="#">See Department</a>
            </div>
          </div>

          <div class="member">
            <img src="./assets/images/departments/Head of Department - Corporate Services photo of Ms Nelisiwe Thabatha .jpg" alt="member_image">
            <h3>Head of Department</h3>
            <span>doe@gmail.com</span>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.amet consecteturamet consecteturamet Laboriosam voluptatum fuga iure. Est, dicta voluptatum.</p>
            <div class="btn">
              <a href="#">See Department</a>
            </div>
          </div>
      </div>
    </div>
    
    </body>