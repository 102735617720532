import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GovernanceComponent } from './governance/governance.component';
import { DepartmentsComponent } from './departments/departments.component';
import { TendersComponent } from './tenders/tenders.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainPageComponent } from './main-page/main-page.component'
import { MoreComponent } from './more/more.component';

const routes: Routes = 
[
{path:'Departments', component: DepartmentsComponent},
{path:'Governance', component: GovernanceComponent},
{path:'Tenders', component: TendersComponent},
{path:'More', component: MoreComponent},
{path:'', component: MainPageComponent},

// {path:'**', component:PageNotFoundComponent}
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



