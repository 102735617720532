<div class ="governancePage container">
    <h1>More</h1>
    <h5>More ways in which the council can assist you</h5>

    <h6>
        Offices of local government comprise of:</h6>
<ul class = "row">
    <li class="col-md-12">Vacancies</li>
        <li class="col-md-12">Vacancies</li>
        <li class="col-md-12">Documents</li>
        <li class="col-md-12">Top Management Profiles</li>
</ul>
     
</div>
