<div class="news-events-banner">
    <section class="arya stark">
        <div class="sheet center middle" style="padding: 55px 0px 0px;">
            <div class="tile" style="transform:none;">
              <div class="copy center black" style="width: 1366px !important;">
        <!--         <marquee behavior="scroll" direction="left">
                  <h1 style="font-size: 100px;">STRONG. DURABLE. </h1>
                </marquee>
         --><h1>News and Events</h1>
                <div class="marquee">
                  <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
        
              </div>
            </div>
          </div>
          </section>
</div>