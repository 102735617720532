import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DepartmentsComponent } from './departments/departments.component';
import { GovernanceComponent } from './governance/governance.component';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './navbar/navbar.component';
import { TendersComponent } from './tenders/tenders.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainPageComponent } from './main-page/main-page.component';
import { AdditionalsComponent } from './additionals/additionals.component';
import { Footer2Component } from './footer2/footer2.component';
import { MoreComponent } from './more/more.component';
import { VisionMissionComponent } from './vision-mission/vision-mission.component';
import { NewsEventsBannerComponent } from './news-events-banner/news-events-banner.component';



@NgModule({
  declarations: [
    AppComponent,
    DepartmentsComponent,
    GovernanceComponent,
    NavbarComponent,
    TendersComponent,
    PageNotFoundComponent,
    MainPageComponent,
    AdditionalsComponent,
    Footer2Component,
    MoreComponent,
    VisionMissionComponent,
    NewsEventsBannerComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,HttpClientModule, BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
