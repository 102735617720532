import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'news-events-banner',
  templateUrl: './news-events-banner.component.html',
  styleUrls: ['./news-events-banner.component.css']
})
export class NewsEventsBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
